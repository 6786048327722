<template>
  <div>
    <GenericTable
      ref="lista"
      name=""
      title="Materiais Sem QR Code"
      add-route="materialSemQRCodeAdicionar"
      edit-route="materialSemQRCodeEditar"
      historicoRoute="materialSemQRCodeHistorico"
      :cols="[
        'Tipo de material',
        'Descrição',
        'Nº de peças',
      ]"
      :cols-name="[
        'tipoMaterialGenerico',
        'descricao',
        'pecas',
      ]"
      :cols-map="
        (i) => [
          i.tipoMaterialGenerico,
          i.descricao,
          i.pecas,
        ]
      "
      route="materialGenerico"
      :permissionsToWrite="['rw_cadastro_rapido']"
      :filters="filters"
      idKey="id_material_generico"
      hasFotografia
      hasPagination
      :materiaisGenericos="materiais"
      @open-modal-fotografia="openModalFotografia"
      :customSearchHandler="materialGenericSearch"
    >
      <template #filters>
        <b-row>
          <b-col sm="12" md="12" lg="12" xl="12">
            <b-form-group
              label="Descrição"
            >
              <b-form-input
                class="invision-select-typing w-70"
                name="material_generico"
                v-model="filters.descricao"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="12" md="12" lg="12" xl="12">
            <b-form-group label="Tipo Material">
              <b-form-select
                class="invision-input mr-1 w-30"
                ref="selectTipoMaterialGenerico"
                name="tipo_material_generico"
                v-model="filters.idTipoMaterialGenerico"
                :options="tipoMaterialGenericoOptions"
              >
                <template slot="first">
                  <option :value="null">
                    -- Selecione uma opção --
                  </option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
      </template>
    </GenericTable>

    <Modal
      id="modalPhoto"
      ref="modalPhoto"
      nome="modalPhoto"
      title="Fotos do Material"
      no-close-on-esc
      no-close-on-backdrop
      size="md"
      centered
    >
      <PhotoView
        :options="{modalPhotos}"
        position="relative"
        margin="0 0 0 -1%"
      />
    </Modal>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Modal from '@/components/Utils/Modal';
import PhotoView from '@/components/Utils/PhotoView';

export default {
  name: 'listMaterialGenerico',
  components: {
    Modal,
    PhotoView,
  },
  data() {
    return {
      filters: {
        idTipoMaterialGenerico: null,
        descricao: null,
      },
      photosName: 'Material',
      materiaisGenericosOptions: [],
      tipoMaterialGenericoOptions: [],
      materiais: [],
      chaveTipoMaterialGenerico: '',
      modalPhotos: [],
    };
  },

  computed: {
    ...mapState(['materiaisGenericos', 'materiaisGenericosByTipo']),
  },

  async mounted() {
    if (!this.materiaisGenericos) {
      await this.getMateriaisGenericosByTipo();
    }
    this.materiais = this.materiaisGenericos;
    this.tipoMaterialGenericoOptions = this.materiaisGenericosByTipo.map((tmg) => ({
      text: tmg.nome,
      value: tmg.id_tipo_material_generico,
    }));
  },

  methods: {
    ...mapActions(['getMateriaisGenericosByTipo']),
    materialGenericSearch() {
      let materiaisFilters = this.materiaisGenericos
      if (this.filters.idTipoMaterialGenerico) {
        const tiposMateriais = this.tipoMaterialGenericoOptions.find(_tiposMateriais =>+_tiposMateriais.value === +this.filters.idTipoMaterialGenerico)

        const materiaisFiltered = materiaisFilters.reduce((acc, material) => {
          if(!material.tipoMaterialGenerico.match(new RegExp(tiposMateriais.text, 'i'))) return acc
          return [...acc, { ...material }]
        }, []);
        materiaisFilters = materiaisFiltered
      }
      if (this.filters.descricao) {
        const materiaisFiltered = materiaisFilters.reduce((acc, material) => {
          if(this.filters.descricao && !material.descricao.match(new RegExp(this.filters.descricao, 'i'))) return acc
          return [...acc, { ...material }]
        }, []);
        materiaisFilters = materiaisFiltered
      }
      this.materiais = materiaisFilters
    },
    openModalFotografia(v) {
      this.modalPhotos = v.materialGenerico.fotoMaterialGenerico;
      this.photosName = `${v.materialGenerico.descricao}_ID-${v.materialGenerico.id_material_generico}`;
      this.$refs.modalPhoto.show();
    },
    closeModalFoto() {
      this.modalPhotos = [];
      this.$refs.modalPhoto.hide();
    },
  },
};
</script>
<style>
.invision-select-typing .vs__dropdown-toggle {
  height: 100%;
  padding: 0;
  border-radius: 7px;
  border: 1px solid #ced4da;
}
.invision-select-typing .vs__selected {
  color: #5E627A;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
}
.invision-select-typing .vs__search {
  color: #5E627A;
  font-size: 14px;
  line-height: 30px;
  font-weight: 400;
}
.invision-select-typing .vs__dropdown-option {
  color: #5E627A;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
}

:scope
.modalPhoto {
  width: 100% important;
}
</style>
